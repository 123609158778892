<template>
  <div class="searchForm" @keyup.enter="onSubmit">
    <div>
      <div class="topBtnBox" v-if="topVisible">
        <slot name="topBtn"></slot>
      </div>
      <el-tabs v-show="tabVisible" type="border-card" v-model="activeName" @tab-click="handleTabClick">
        <el-tab-pane v-for="(item, index) in tabData" :key="index" class="searchTab" :label="item.name + '(' + (item.count ? item.count : 0) + ')'"></el-tab-pane>
      </el-tabs>
    </div>
    <!-- <el-card> -->
    <div class="searchFormBox" v-show="searchVisible">
      <div :class="active ? 'searchFormDiv' : 'searchFormStrDiv'">
        <el-form :inline="true" :model="createData" ref="searchForm" :label-width="labelWidth">
          <el-col v-for="(item, index) in searchFormData" :key="index" :xs="24" :sm="24" :md="12" :lg="item.type === 'date_range' ? 2*item.span : item.span" :xl="item.span">
          <!-- <el-col v-for="(item, index) in searchFormData" :key="index" :xs="24" :sm="24" :md="12" :lg="item.span" :xl="item.span"> -->
            <el-form-item class="searchFormItem" >
              <el-input :disabled="item.disabled" :placeholder="$t('placeholder.input', {name: item.name})" v-if="item.type === 'text'" v-model="createData[item.query_name]"></el-input>
              <el-select :disabled="item.disabled" clearable style="width:100%" filterable v-if="item.type === 'select'" v-model="createData[item.query_name]" :placeholder="$t('placeholder.select', {name: item.name})">
                <el-option v-for="option in item.values" :label="option.name | getNull" :value="option.value" :key="option.value"></el-option>
              </el-select>
              <el-date-picker :disabled="item.disabled" style="width:100%" type="date" value-format="yyyy-MM-dd" v-if="item.type === 'date'" :placeholder="$t('placeholder.select', {name: item.name.trim()})" v-model="createData[item.query_name]"></el-date-picker>
              <el-date-picker
                :disabled="item.disabled"
                style="width:100%"
                type="daterange"
                v-if="item.type === 'date_range'"
                v-model="createData[item.query_name]"
                value-format="yyyy-MM-dd"
                :range-separator="$t('text.to')"
                :start-placeholder="item.name"
                :end-placeholder="item.name">
              </el-date-picker>
              <el-time-picker
                :disabled="item.disabled"
                style="width:100%"
                format="HH:mm"
                v-if="item.type === 'time'"
                v-model="createData[item.query_name]"
                :placeholder="$t('placeholder.select', {name: item.name})">
              </el-time-picker>
              <el-cascader
                :disabled="item.disabled"
                v-if="item.type === 'cascader'"
                style="width:100%"
                v-model="createData[item.query_name]"
                :options="item.values"
                @change="handleChange($event, item.query_name)">
              </el-cascader>
            </el-form-item>
          </el-col>
        </el-form>
      </div>
      <div class="searchFormBtn" v-if="searchFormData.length > 0">
          <i class="searchFormIcon" v-if="stretchVisible" :class=" active ? 'el-icon-arrow-down' : 'el-icon-arrow-up'" @click="handleTaggle" />
          <el-button type="primary" @click="onSubmit">{{$t('button.search')}}</el-button>
          <el-button @click="clearData">{{$t('button.reset')}}</el-button>
      </div>
    </div>
    <!-- </el-card> -->
  </div>
</template>

<script>
import Utils from '@/utils/Utils'
import loading from '@/utils/loading.json'
import _ from 'lodash'
export default {
  name: 'searchForm',
  props: {
    searchFormData: {
      type: Array,
      default () {
        return []
      }
    },
    labelWidth: {
      type: String,
      default () {
        return '50px'
      }
    },
    tabData: {
      type: Array,
      default () {
        return []
      }
    },
    topVisible: {
      type: Boolean,
      default () {
        return false
      }
    },
    stretchVisible: {
      type: Boolean,
      default () {
        return false
      }
    },
    searchVisible: {
      type: Boolean,
      default () {
        return true
      }
    },
    tabVisible: {
      type: Boolean,
      default () {
        return true
      }
    }
  },
  data () {
    return {
      activeName: '0',
      active: false,
      createData: {},
      outData: {}
    }
  },
  watch: {
    activeName: {
      handler (val) {
        // this.$emit('tabChange', val)
        // this.onSubmit()
      }
    }
  },
  created () {
    this.bindData()
  },
  methods: {
    // 绑定表单数据
    bindData () {
      if (this.searchFormData.length > 0) {
        this.searchFormData.forEach(res => {
          if (res.disabled) return
          if (res.type === 'date_range') this.$set(this.createData, res.query_name, [])
          else this.$set(this.createData, res.query_name, '')
        })
      }
    },
    getCreateData (val) {
      this.outData = _.cloneDeep(this.createData)
      this.searchFormData.forEach(res => {
        if (res.type === 'date_range' && this.outData[res.query_name]) {
          this.outData[res.query_name] = this.outData[res.query_name].join('to')
        }
      })
      if (val && Object.keys(val).length > 0) {
        Object.assign(this.outData, val)
      }
      if (this.tabData[this.activeName] && this.tabData[this.activeName].value) Object.assign(this.outData, { scope: this.tabData[this.activeName].value })
    },
    // 提交查询表单数据
    onSubmit (val) {
      // console.log(this.tabData[this.activeName])
      // console.log(this.createData)
      this.getCreateData(val)
      this.$emit('search', this.outData)
    },
    // 隐藏显示
    handleTaggle () {
      this.active = !this.active
    },
    exportExcel (api, el) {
      this.$store.state.app.loading = el.$loading(loading)
      this.getCreateData()
      api(this.outData).then(res => {
        if (res.status === 200) {
          Utils.fileExport(res)
          this.$message({
            type: 'success',
            message: this.$t('message.exportSuccess')
          })
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    commonOperate (api, fuc, el) {
      this.$store.state.app.loading = el.$loading(loading)
      this.getCreateData()
      api(this.outData).then(res => {
        if (res.status === 200 || res.status === 204) {
          // Utils.fileExport(res)
          fuc()
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    // 级联
    handleChange (val) {
    },
    // 清空表单数据
    clearData () {
      this.bindData()
      // this.$refs.searchForm.resetFields()
    },

    handleTabClick () {
      this.$emit('tabChange', this.activeName)
      this.onSubmit()
    }
  }
}
</script>

<style lang="scss">
  .searchForm {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    input::placeholder {
      color: #909399
    }
    .el-date-editor {
      input::placeholder {
        color: #909399
      }
    }
    .el-form-item--small.el-form-item {
      margin-bottom: 10px !important;
    }
    .el-tabs__nav-prev {
      text-align: center;
      width: 20px;
      background: #40586F;
      color: #fff;
    }
    .el-tabs__nav-next {
      text-align: center;
      width: 20px;
      background: #40586F;
      color: #fff;
    }
    .el-tabs__item {
      color: #606266 !important;
    }
    .is-active {
      color: #40586F !important;
    }
    .topBtnBox {
      overflow: auto;
      background: #fff;
      padding: 10px 0;
      display: flex;
    }
    .searchFormBox {
      @media screen and (max-width: 767px){
        flex-direction: column;
      }
      display: flex;
      margin-top: 16px;
      position: relative;
      .searchFormStrDiv {
        > form::after {
          content: '';
          display: block;
          clear: both;
        }
        @media screen and (max-width: 767px){
          width: 100%;
        }
        width: calc(100% - 200px);
        transition: max-height 0.2s;
        max-height: 500px;
        overflow: hidden;
      }
      .searchFormDiv {
        height:auto;
        max-height: 84px;
        transition: max-height 0.2s;
        overflow: hidden;
      }
      .searchFormIcon {
        margin-right: 16px;
      }
    }
    .searchFormItem  {
      display: flex;
      .el-form-item__label {
        flex-shrink: 0
      }
      .el-form-item__content {
        width: 100%;
      }
    }
    .searchFormBtn {
      // width: 100%;
      @media screen and (max-width: 767px){
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
      }
      min-width: 200px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
    }
    .el-tabs--border-card > .el-tabs__header {
      border: 0;
    }
    .el-tabs--border-card {
      box-shadow: none;
      border: 0;
    }
    .el-tabs__content {
      display: none;
    }
  }
</style>
